<template>
  <form-view
    :url="`/car/${!dataForm.id ? 'save' : 'updateById'}`"
    :data-form="dataForm"
    v-loading="loading"
    :custom-validator="customValidator"
    :before-submit="beforeSubmit"
    :label-width="80"
  >
    <div class="section-title-row">
      <div class="title">车辆基本信息</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="自编号" prop="number">
          <el-input v-model="dataForm.number"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="车牌号码" prop="licensePlate">
          <el-input v-model="dataForm.licensePlate"></el-input>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="8">
        <el-form-item label-width="102px" label="终端号" prop="carDeviceId">
          <el-input v-model="dataForm.carDeviceId"></el-input>
        </el-form-item>
      </el-col> -->
      <el-col :span="8">
        <el-form-item label="车辆泵别" prop="carType">
          <el-select filterable v-model="dataForm.carType">
            <el-option
              v-for="s in carType"
              :key="s.dictId"
              :label="s.dictName"
              :value="s.dictId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="车辆类别" prop="carSize">
          <el-input v-model="dataForm.carSize" :controls="false"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="出厂编号" prop="factoryNumber">
          <el-input v-model="dataForm.factoryNumber"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label-width="102px" label="启用日期" prop="startupDate">
          <el-date-picker value-format="timestamp" v-model="dataForm.startupDate"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="所属公司" prop="companyId">
          <el-select filterable v-model="dataForm.companyId">
            <el-option v-for="s in companyList" :key="s.id" :label="s.companyName" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="排放标准" prop="emissionStandard">
          <el-input v-model="dataForm.emissionStandard"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label-width="102px" label="车辆类型" prop="carGasType">
          <el-select filterable v-model="dataForm.carGasType">
            <el-option
              v-for="s in carGasType"
              :key="s.dictId"
              :label="s.dictName"
              :value="s.dictId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25" v-if="isOwned">
      <el-col :span="8">
        <el-form-item label="所属基地" prop="baseId">
          <el-select filterable v-model="dataForm.baseId">
            <el-option v-for="s in baseList" :key="s.id" :label="s.name" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="发动机号" prop="engineNumber">
          <el-input v-model="dataForm.engineNumber"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="车架号" label-width="100px" prop="identificationNumber">
          <el-input v-model="dataForm.identificationNumber"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="底盘" prop="chassis">
          <el-input v-model="dataForm.chassis"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="底盘品牌" prop="chassisBrand">
          <el-input v-model="dataForm.chassisBrand"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label-width="102px" label="底盘轴数" prop="chassisNumber">
          <el-input-number v-model="dataForm.chassisNumber" :controls="false"></el-input-number>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="负责人" prop="principalName">
          <el-select filterable v-model="dataForm.headUserId">
            <el-option v-for="s in principalList" :key="s.id" :label="s.nickName" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="泵车品牌" prop="carBrand">
          <el-input v-model="dataForm.carBrand"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label-width="102px" label="车辆购买日期" prop="purchaseDate">
          <el-date-picker value-format="timestamp" v-model="dataForm.purchaseDate"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">臂展详情</div>
      <div class="line"></div>
    </div>
    <div class="bracket-area">
      <el-row>
        <el-col :span="6">
          <el-form-item label-width="110px" label="支腿类型" prop="bracketType">
            <el-select filterable v-model="dataForm.bracketType">
              <el-option
                v-for="s in bracketType"
                :key="s.dictId"
                :label="s.dictName"
                :value="s.dictId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label-width="110px" label="全展前左腿长度" prop="bracketFullBeforeLeft">
            <el-input type="number" v-model="dataForm.bracketFullBeforeLeft"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label-width="110px" label="半展前左腿长度" prop="bracketHalfBeforeLeft">
            <el-input type="number" v-model="dataForm.bracketHalfBeforeLeft"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label-width="110px" label="全展前右腿长度" prop="bracketFullBeforeRight">
            <el-input type="number" v-model="dataForm.bracketFullBeforeRight"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label-width="110px" label="半展前右腿长度" prop="bracketHalfBeforeRight">
            <el-input type="number" v-model="dataForm.bracketHalfBeforeRight"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label-width="110px" label="全展后左腿长度" prop="bracketFullAfterLeft">
            <el-input type="number" v-model="dataForm.bracketFullAfterLeft"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label-width="110px" label="半展后左腿长度" prop="bracketHalfAfterLeft">
            <el-input type="number" v-model="dataForm.bracketHalfAfterLeft"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label-width="110px" label="全展后右腿长度" prop="bracketFullAfterRight">
            <el-input type="number" v-model="dataForm.bracketFullAfterRight"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label-width="110px" label="半展后右腿长度" prop="bracketHalfAfterRight">
            <el-input type="number" v-model="dataForm.bracketHalfAfterRight"></el-input>
            <label>米</label>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <div class="section-title-row" >
      <div class="title">驾操人员信息<i class="icon el-icon-circle-plus" @click="insertPerson()"></i></div>
      <div class="line"></div>
    </div>
    <el-row class="flex-row" v-for="(d, i) in dataForm.carDriverList" :key="i">
      <label>岗位</label>
      <el-select filterable v-model="d.post">
        <el-option v-for="s in carPost" :key="s.dictId" :label="s.dictName" :value="s.dictId"></el-option>
      </el-select>
      <label>用户姓名</label>
      <el-select filterable v-model="d.userId">
        <el-option v-for="s in userList" :key="s.id" :label="s.nickName" :value="s.id"></el-option>
      </el-select>
      <label></label>
      <el-select filterable v-model="d.functionName">
        <el-option v-for="s in carDriver" :key="s.dictId" :label="s.dictName" :value="s.dictId"></el-option>
      </el-select>
      <label>备注</label>
      <el-input class="remark" v-model="d.remarks"></el-input>
      <i
        class="icon el-icon-delete-solid"
        @click="removePerson(i)"
      ></i>
    </el-row>
    <!-- <div class="section-title-row" style="margin-top:40px">
      <div class="title">驾操人员历史记录</div>
      <div class="line"></div>
    </div>
    <div class="block">
      <el-timeline :reverse="reverse">
        <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          :timestamp="activity.timestamp">
          {{activity.content}}
        </el-timeline-item>
      </el-timeline>
    </div> -->
    <!-- 保险 start -->
    <div class="section-title-row">
      <div class="title">保险记录<i class="icon el-icon-circle-plus" @click="insertInsurance()"></i></div>
      <div class="line"></div>
    </div>
    <div class="insurance-area" v-for="(r, i) in dataForm.carInsuranceList" :key="'ins_' + i">
      <el-row class="flex-row el-form-item is-required">
        <label class="el-form-item__label">开始时间</label>
        <el-date-picker value-format="timestamp" v-model="r.startDate"></el-date-picker>
        <label class="el-form-item__label">结束时间</label>
        <el-date-picker value-format="timestamp" v-model="r.endDate"></el-date-picker>
        <label class="el-form-item__label">保险类型</label>
        <el-select v-model="r.insuranceType">
          <el-option
            v-for="s in carInsurance"
            :key="s.dictId"
            :label="s.dictName"
            :value="s.dictId"
          ></el-option>
        </el-select>
        <label class="el-form-item__label">保险公司</label>
        <el-input v-model="r.insuranceCompany"></el-input>
        <i
          class="icon el-icon-delete-solid"
          @click="removeInsurance(i)"
          v-if="dataForm.carInsuranceList.length > 1"
        ></i>
      </el-row>
      <image-uploader
        @add="addInsuranceImage($event, r)"
        @remove="removeInsuranceImage($event, r)"
        :images="r.images || []"
        no-title
      ></image-uploader>
    </div>
    <!-- 保险 end -->
    <!-- 合同 start -->
    <div class="section-title-row">
      <div class="title">租赁合同(仅租借车辆)<i class="icon el-icon-circle-plus" @click="insertContract()"></i></div>
      <div class="line"></div>
    </div>
    <div class="insurance-area" v-for="(r, i) in dataForm.carContractList" :key="'con_' + i">
      <el-row class="flex-row el-form-item is-required">
        <label class="el-form-item__label">开始时间</label>
        <el-date-picker value-format="timestamp" v-model="r.startDate"></el-date-picker>
        <label class="el-form-item__label">结束时间</label>
        <el-date-picker value-format="timestamp" v-model="r.endDate"></el-date-picker>
        <i
          class="icon el-icon-delete-solid"
          @click="removeContract(i)"
          v-if="dataForm.carContractList.length > 1"
        ></i>
      </el-row>
      <image-uploader
        @add="addContractImage($event, r)"
        @remove="removeContractImage($event, r)"
        :images="r.images || []"
        no-title
      ></image-uploader>
    </div>
    <!-- 合同 end -->
    <div class="section-title-row">
      <div class="title">行驶证信息</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <!-- <el-col :span="8">
        <el-form-item label="车架号" prop="identificationNumber" >
          <el-input v-model="dataForm.identificationNumber"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="发动机号" prop="engineNumber">
          <el-input v-model="dataForm.engineNumber"></el-input>
        </el-form-item>
      </el-col> -->
      <el-col :span="8">
        <el-form-item label-width="120px" label="行驶证到期日期" prop="drivingLicenseExpireDate">
          <el-date-picker value-format="timestamp" v-model="dataForm.drivingLicenseExpireDate"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="10">
        <div class="license-area">
          <div class="upload-wrapper">
            <image-uploader
              :limit="1"
              @add="addLicenseImage($event, 'Before')"
              @remove="removeLicenseImage('Before')"
              :images="beforeImageList || []"
              no-title
            ></image-uploader>
            <div class="title">行驶证正面</div>
          </div>
          <div class="upload-wrapper">
            <image-uploader
              :limit="1"
              @add="addLicenseImage($event, 'After')"
              @remove="removeLicenseImage('After')"
              :images="afterImageList || []"
              no-title
            ></image-uploader>
            <div class="title">行驶证反面</div>
          </div>
          <div class="upload-wrapper">
            <image-uploader
              :limit="1"
              @add="addLicenseImage($event, 'SubCard')"
              @remove="removeLicenseImage('SubCard')"
              :images="subCardImageList || []"
              no-title
            ></image-uploader>
            <div class="title">行驶证副卡</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- <div class="section-title-row">
      <div class="title">车辆照片</div>
      <div class="line"></div>
    </div>
    <el-row >
      <el-col :span="24">
        <image-uploader
          @add="addImage"
          @remove="removeImage"
          :images="dataForm.images || []"
          no-title
        ></image-uploader>
      </el-col>
    </el-row> -->
    <div class="section-title-row" v-if="id">
      <div class="title">操作历史</div>
      <div class="line"></div>
    </div>
    <div class="record-area" v-if="id">
      <div class="record-row" v-for="(r, i) in records" :key="i">{{r}}</div>
    </div>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import ImageUploader from '@/components/image-uploader'
import dayjs from 'dayjs'
import { operationTypes } from '@/utils/constants'

export default {
  name: 'car-car-update',

  components: { FormView, ImageUploader },

  data () {
    return {
      loading: false,
      dataForm: {},
      sizeList: [],
      carGasType: [],
      carType: [],
      companyList: [],
      baseList: [],
      bracketType: [],
      carInsurance: [],
      carPost: [],
      carDriver: [],
      userList: [],
      beforeImageList: [],
      afterImageList: [],
      subCardImageList: [],
      records: [],
      id: null,
      reverse: true,
      activities: [{
        content: '张小龙',
        timestamp: '2020-04-15'
      }, {
        content: '李峰城',
        timestamp: '2020-04-13'
      }, {
        content: '袁天丽',
        timestamp: '2020-04-11'
      }],
      principalList: []
    }
  },

  computed: {
    isOwned () {
      return this.companyList.length &&
        this.dataForm.companyId &&
        this.companyList.find(c => c.id === this.dataForm.companyId).companyType === 1
    }
  },

  async created () {
    const userData = await this.$http({
      url: this.$http.adornUrl('/user/list'),
      method: 'post',
      data: {
        userType: 1
      }
    })
    this.principalList = userData.datas
    this.init()
  },

  methods: {
    async init () {
      const id = this.id = this.$route.query.id
      this.loading = true
      if (id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/car/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          this.dataForm.startupDate = dayjs(this.dataForm.startupDate).valueOf()
          this.dataForm.purchaseDate = dayjs(this.dataForm.purchaseDate).valueOf()
          this.dataForm.drivingLicenseExpireDate = dayjs(this.dataForm.drivingLicenseExpireDate).valueOf()

          this.dataForm.carInsuranceList.forEach(i => {
            i.startDate = dayjs(i.startDate).valueOf()
            i.endDate = dayjs(i.endDate).valueOf()
            i.insuranceType = parseInt(i.insuranceType) || null
            if (i.insuranceImage) {
              i.images = i.insuranceImage.split(',').map(j => ({ url: '/files' + j }))
              i.insuranceImageList = i.images.map(j => j.url.substring('/files'.length))
            } else {
              i.images = []
              i.insuranceImageList = []
            }
          })
          if (this.dataForm.carContractList.length) {
            this.dataForm.carContractList.forEach(i => {
              i.startDate = dayjs(i.startDate).valueOf()
              i.endDate = dayjs(i.endDate).valueOf()
              if (i.Image) {
                i.images = i.Image.split(',').map(j => ({ url: '/files' + j }))
                i.contractImageList = i.images.map(j => j.url.substring('/files'.length))
              } else {
                i.images = []
                i.contractImageList = []
              }
            })
          } else {
            this.insertContract()
          }
          this.dataForm.bracketType = parseInt(this.dataForm.bracketType) || null
          this.dataForm.carGasType = parseInt(this.dataForm.carGasType) || null
          this.dataForm.drivingLicenseBeforeImage && (this.beforeImageList = [{ url: '/files' + this.dataForm.drivingLicenseBeforeImage }])
          this.dataForm.drivingLicenseAfterImage && (this.afterImageList = [{ url: '/files' + this.dataForm.drivingLicenseAfterImage }])
          this.dataForm.drivingLicenseSubCardImage && (this.subCardImageList = [{ url: '/files' + this.dataForm.drivingLicenseSubCardImage }])
          if (!this.dataForm.carDriverList.length) {
            this.insertPerson()
          }
          if (!this.dataForm.carInsuranceList.length) {
            this.insertInsurance()
          }
          const res = await this.$http({
            url: this.$http.adornUrl('/car/operationRecord'),
            method: 'post',
            data: id
          })
          this.records = res.datas.map(d => {
            return `${d.createTime} ${d.nickName}进行了${operationTypes[d.typeCode]}操作。`
          })
          this.loading = false
        }
      } else {
        this.dataForm = {
          number: '',
          licensePlate: '',
          carDeviceId: '',
          carSize: '',
          factoryNumber: '',
          startupDate: '',
          companyId: '',
          emissionStandard: '',
          carGasType: '',
          carType: '',
          baseId: '',
          engineNumber: '',
          identificationNumber: '',
          chassis: '',
          chassisBrand: '',
          chassisNumber: '',
          headUserId: '',
          carBrand: '',
          purchaseDate: '',
          bracketType: '',
          bracketFullBeforeLeft: '',
          bracketHalfBeforeLeft: '',
          bracketFullBeforeRight: '',
          bracketHalfBeforeRight: '',
          bracketFullAfterLeft: '',
          bracketHalfAfterLeft: '',
          bracketFullAfterRight: '',
          bracketHalfAfterRight: '',
          carDriverList: [
            { post: '', userId: '', functionName: '', remarks: '' },
            { post: '', userId: '', functionName: '', remarks: '' }
          ],
          carInsuranceList: [
            { startDate: '', endDate: '', insuranceType: '', insuranceCompany: '', insuranceImageList: [], images: [] }
          ],
          carContractList: [
            { startDate: '', endDate: '', contractImageList: [], images: [] }
          ],
          drivingLicenseExpireDate: '',
          drivingLicenseBeforeImage: '',
          drivingLicenseAfterImage: '',
          drivingLicenseSubCardImage: ''
        }
        this.loading = false
      }
      let data = await this.$http({
        url: this.$http.adornUrl('/car/otherData'),
        method: 'post'
      })
      for (const key in data.datas) {
        if (data.datas.hasOwnProperty(key)) {
          const v = data.datas[key]
          this[key] = v
        }
      }
      data = await this.$http({
        url: this.$http.adornUrl('/car/listCarSize'),
        method: 'post'
      })
      this.sizeList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/user/list'),
        method: 'post',
        data: {}
      })
      this.userList = data.datas
    },

    insertPerson (i = 0) {
      this.dataForm.carDriverList.splice(i + 1, 0,
        { post: '', userId: '', functionName: '', remarks: '' }
      )
    },

    removePerson (i) {
      this.$confirm('确定删除记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let item = this.dataForm.carDriverList[i]
        if (item['id']) {
          this.$http({
            url: this.$http.adornUrl('/car/cardriver/delete'),
            method: 'post',
            data: item['id']
          })
        }
        this.dataForm.carDriverList.splice(i, 1)
      }).catch(() => { })
    },

    insertInsurance (i = 0) {
      this.dataForm.carInsuranceList.splice(i + 1, 0,
        { startDate: '', endDate: '', insuranceType: '', insuranceCompany: '', insuranceImageList: [], images: [] }
      )
    },

    removeInsurance (i) {
      this.$confirm('确定删除记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let item = this.dataForm.carInsuranceList[i]
        if (item['id']) {
          this.$http({
            url: this.$http.adornUrl('/car/insurance/delete'),
            method: 'post',
            data: item['id']
          })
        }
        this.dataForm.carInsuranceList.splice(i, 1)
      }).catch(() => { })
    },

    addInsuranceImage (path, row) {
      row.insuranceImageList.push(path)
    },

    removeInsuranceImage (url, row) {
      row.insuranceImageList.splice(row.insuranceImageList.indexOf(url), 1)
    },
    insertContract (i = 0) {
      console.log(this.dataForm.carContractList)
      if (!this.dataForm.carContractList) {
        this.dataForm.carContractList = []
      }
      this.dataForm.carContractList.splice(i + 1, 0,
        { startDate: '', endDate: '', contractImageList: [], images: [] }
      )
    },

    removeContract (i) {
      this.$confirm('确定删除记录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let item = this.dataForm.carContractList[i]
        if (item['id']) {
          this.$http({
            url: this.$http.adornUrl('/car/contract/delete'),
            method: 'post',
            data: item['id']
          })
        }
        this.dataForm.carContractList.splice(i, 1)
      }).catch(() => { })
    },

    addContractImage (path, row) {
      row.contractImageList.push(path)
    },

    removeContractImage (url, row) {
      row.contractImageList.splice(row.contractImageList.indexOf(url), 1)
    },

    addImage (path) {
      this.dataForm.imageList.push(path)
    },

    removeImage (url) {
      this.dataForm.imageList.splice(this.dataForm.imageList.indexOf(url), 1)
    },

    addLicenseImage (path, name) {
      this.dataForm[`drivingLicense${name}Image`] = path
    },

    removeLicenseImage (name) {
      this.dataForm[`drivingLicense${name}Image`] = ''
    },

    customValidator () {
      // const { carDriverList } = this.dataForm
      // const driverID = this.carPost.find(p => p.dictName === '驾驶员').dictId
      // const operatorID = this.carPost.find(p => p.dictName === '操作员').dictId
      // if (!carDriverList.find(c => c.post === driverID)) {
      //   this.$opts.alert('必须有一名以上的驾驶员！')
      //   return false
      // }
      // if (!carDriverList.find(c => c.post === operatorID)) {
      //   this.$opts.alert('必须有一名以上的操作员！')
      //   return false
      // }
      // const masterID = this.carDriver.find(p => p.dictName === '车长').dictId
      // const l = carDriverList.filter(c => c.functionName === masterID).length
      // if (l === 0) {
      //   this.$opts.alert('必须有一名车长！')
      //   return false
      // }
      // if (l > 1) {
      //   this.$opts.alert('只能选择一名车长！')
      //   return false
      // }
      if (this.dataForm.carGasType == null) {
        this.$opts.alert('车辆类型必选')
        return false
      }
      if (this.dataForm.carType == null) {
        this.$opts.alert('车辆泵别必选')
        return false
      }
      return true
    },

    beforeSubmit (params) {
      const { carInsuranceList, carContractList } = params
      carInsuranceList.forEach(c => {
        c.insuranceImage = c.insuranceImageList.join(',')
      })
      if (carContractList) {
        carContractList.forEach(c => {
          c.image = c.contractImageList.join(',')
        })
      }
      return params
    }
  }
}
</script>

<style lang="scss" scoped>
.bracket-area {
  .el-form-item__content {
    white-space: nowrap;
    .el-input {
      width: 80%;
    }
    .el-input + label {
      margin-left: 10px;
    }
  }
}
.flex-row {
  margin: 20px 0;
  display: flex;
  align-items: center;
  .el-select,
  .el-date-editor {
    width: 150px;
    flex: 0 0 auto;
  }
  label {
    margin: 0 10px;
    white-space: nowrap;
  }
  .el-input.remark {
    flex: 1;
    width: auto;
  }
  .icon {
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
    color: #666;
    &:hover {
      color: #999;
    }
  }
}
.insurance .license-area {
  justify-content: flex-start;
}
.license-area {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
</style>
